import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import axios from "axios";

Vue.use(Router);
let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/appSidebar",
      props: true,
      component: () => import("./components/Common/appSidebar"),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          path: "/DashboardPage",
          name: "DashboardPage",
          meta: {
            requireAuth: true,roles: ['admin'] 
          },
          component: () => import("./components/admin/DashboardPage.vue"),
        },
        {
          path: "/NatureCamps",
          name: "NatureCamps",
          meta: {
            requireAuth: true,roles: ['user'] 
          },
          component: () => import("./components/user/NatureCamps.vue"),
        },



        {
          path: "/CampDetailedView",
          name: "CampDetailedView",
          meta: {
            requireAuth: true,roles: ['user','clerk','superint','division','admin'] 
          },
          component: () => import("./components/user/CampDetailedView.vue"),
        },
        {
          path: "/profilepage",
          name: "profilepage",
          meta: {
            requireAuth: true,roles: ['user','clerk','superint','division','adminclerk','adminsuperint','admin'] 
          },
          component: () => import("./components/Common/profilePage.vue"),
        },
        {
          path: "/editbooking",
          name: "editbooking",
          meta: {
            requireAuth: true,roles: ['user'] 
          },
          component: () => import("./components/user/EditMyBookings.vue"),
        },
        {
          path: "/EditForwardApplication",
          name: "EditForwardApplication",
          meta: {
            requireAuth: true,roles: ['user'] 
          },
          component: () => import("./components/user/EditForwardApplication.vue"),
        },
        {
          path: "/sectionclerk",
          name: "sectionclerk",
          meta: {
            requireAuth: true,roles: ['division']
          },
          component: () => import("./components/division/sectionclerk.vue"),
        },
        {
          path: "/AddClerk",
          name: "AddClerk",
          meta: {
            requireAuth: true,roles: ['admin'] 
          },
          component: () => import("./components/admin/AddClerk.vue"),
        },
        {
          path: "/CancelledApplicationAdmin",
          name: "CancelledApplicationAdmin",
          meta: {
            requireAuth: true,roles: ['admin'] 
          },
          component: () => import("./components/admin/CancelledApplicationAdmin.vue"),
        },
        {
          path: "/AddSuperint",
          name: "AddSuperint",
          meta: {
            requireAuth: true,roles: ['admin'] 
          },
          component: () => import("./components/admin/AddSuperint.vue"),
        },
        {
          path: "/superintedent",
          name: "superintedent",
          meta: {
            requireAuth: true,roles: ['division']
          },
          component: () => import("./components/division/superintedent.vue"),
        },
        {
          path: "/SuperintedentHome",
          name: "SuperintedentHome",
          meta: {
            requireAuth: true,roles: ['superint']
          },
          component: () => import("./components/superintedent/SuperintedentHome.vue"),
        },
        {
          path: "/SuperintedentDashboard",
          name: "SuperintedentDashboard",
          meta: {
            requireAuth: true,roles: ['superint']
          },
          component: () => import("./components/superintedent/SuperintDashboard.vue"),
        },
        {
          path: "/CancelledApplicationSuperintedent",
          name: "CancelledApplicationSuperintedent",
          meta: {
            requireAuth: true,roles: ['superint']
          },
          component: () => import("./components/superintedent/CancelledApplicationSuperintedent.vue"),
        },
        {
          path: "/AdminClerk",
          name: "AdminClerk",
          meta: {
            requireAuth: true,roles: ['adminclerk']
          },
          component: () => import("./components/cwwclerk/CwwclerkHome.vue"),
        },
        {
          path: "/cwwclerkdashboard",
          name: "cwwclerkdashboard",
          meta: {
            requireAuth: true,roles: ['adminclerk']
          },
          component: () => import("./components/cwwclerk/CwwclerkDashboard.vue"),
        },
        {
          path: "/AdminMinisterialhead",
          name: "AdminMinisterialhead",
          meta: {
            requireAuth: true,roles: ['adminsuperint']
          },
          component: () => import("./components/cwwministerial/CwwSuperint.vue"),
        },
        {
          path: "/ClerkHome",
          name: "ClerkHome",
          meta: {
            requireAuth: true,roles: ['clerk']
          },
          component: () => import("./components/clerk/ClerkHome.vue"),
        },
        {
          path: "/ClerkDashboard",
          name: "ClerkDashboard",
          meta: {
            requireAuth: true,roles: ['clerk']
          },
          component: () => import("./components/clerk/ClerkDashboard.vue"),
        },

        {
          path: "/Cwwministerial",
          name: "Cwwministerial",
          meta: {
            requireAuth: true,roles: ['adminsuperint'] 
          },
          component: () => import("./components/cwwministerial/Cwwministerial.vue"),
        },
        {
          path: "/CwwministerialDashboard",
          name: "CwwministerialDashboard",
          meta: {
            requireAuth: true,roles: ['adminsuperint'] 
          },
          component: () => import("./components/cwwministerial/CwwminDashboard.vue"),
        },

        {
          path: "/NatureCampDetails",
          name: "NatureCampDetails",
          meta: {
            requireAuth: true,roles: ['user'] 
          },
          component: () => import("./components/user/NatureCampDetails.vue"),
        },
        {
          path: "/ApplicantDetails",
          name: "ApplicantDetails",
          meta: {
            requireAuth: true,roles: ['user'] 
          },
          component: () => import("./components/user/ApplicantDetails.vue"),
        },
        {
          path: "/cancelledapplications",
          name: "cancelledapplications",
          meta: {
            requireAuth: true,roles: ['user'] 
          },
          component: () => import("./components/user/CancelledApplications.vue"),
        },




        {
          path: "/mybooking",
          name: "mybooking",
          meta: {
            requireAuth: true,roles: ['user'] 
          },
          component: () => import("./components/user/MyBooking.vue"),
        },

        {
          path: "/ForwardedApplications",
          name: "ForwardedApplications",
          meta: {
            requireAuth: true,roles: ['user'] 
          },
          component: () => import("./components/user/ForwardedApplications.vue"),
        },

        {
          path: "/booknow",
          name: "booknow",
          meta: {
            requireAuth: true,roles: ['user'] 
          },
          component: () => import("./components/user/BookNatureCamp.vue"),
        },
        {
          path: "/userviewstatus",
          name: "userviewstatus",
          meta: {
            requireAuth: true,roles: ['user'] 
          },
          component: () => import("./components/user/UserviewStatus.vue"),
        },
        {
          path: "/paywaitscreen",
          name: "paywaitscreen",
          meta: {
            requireAuth: true,roles: ['user'] 
          },
          component: () => import("./components/Payment/PayWaitScreen.vue"),
        },
        {
          path: "/SuccessPage",
          name: "SuccessPage",
          meta: {
            requireAuth: true,roles: ['user'] 
          },
          component: () => import("./components/Payment/SuccessPage.vue"),
        },
        {
          path: "/FailurePage",
          name: "FailurePage",
          meta: {
            requireAuth: true,roles: ['user'] 
          },
          component: () => import("./components/Payment/FailurePage.vue"),
        },
        {
          path: "/cancelled",
          name: "cancelled",
          meta: {
            requireAuth: true,roles: ['admin'] 
          },
          component: () => import("./components/admin/CanclledBooking.vue"),
        },
        {
          path: "/tomorrow",
          name: "tomorrow",
          meta: {
            requireAuth: true,roles: ['admin'] 
          },
          component: () => import("./components/admin/BookinsTomorrow.vue"),
        },
        {
          path: "/PendingApplicationsAdmin",
          name: "PendingApplicationsAdmin",
          meta: {
            requireAuth: true,roles: ['admin'] 
          },
          component: () => import("./components/admin/PendingApplicationsAdmin.vue"),
        },
        {
          path: "/AdminBookinsPage",
          name: "AdminBookinsPage",
          meta: {
            requireAuth: true,roles: ['admin'] 
          },
          component: () => import("./components/admin/AdminBookinsPage.vue"),
        },

        {
          path: "/CampDetailedViewAdmin",
          name: "CampDetailedViewAdmin",
          meta: {
            requireAuth: true,roles: ['admin'] 
          },
          component: () => import("./components/admin/CampDetailedViewAdmin.vue"),
        },
        {
          path: "/records",
          name: "records",
          meta: {
            requireAuth: true,roles: ['user','admin','clerk','superint','division','adminsuperint','adminclerk'] 
          },
          component: () => import("./components/user/RecordsPage.vue"),
        },
        {
          path: "/recordspage",
          name: "recordspage",
          meta: {
            requireAuth: true,roles: ['user','admin','clerk','superint','division','adminsuperint','adminclerk'] 
          },
          component: () => import("./components/user/RecordspageUser.vue"),
        },
        {
          path: "/view",
          name: "view",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/admin/BookingDetailedview.vue"),
        },
        {
          path: "/divisionNatureCamp",
          name: "divisionNatureCamp",
          meta: {
            requireAuth: true,roles: ['division'] 
          },
          component: () => import("./components/division/divisionNatureCamp.vue"),
        },
        {
          path: "/ViewReservations",
          name: "ViewReservations",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/division/ViewReservations.vue"),
        },
        {
          path: "/CancelledApplicationDivision",
          name: "CancelledApplicationDivision",
          meta: {
            requireAuth: true,roles: ['division'] 
          },
          component: () => import("./components/division/CancelledApplicationDivision.vue"),
        },

        {
          path: "/bookib",
          name: "bookib",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/division/IbBook.vue"),
        },

        {
          path: "/ApprovedApplicationsAdmin",
          name: "ApprovedAppsAdmin",
          meta: {
            requireAuth: true,roles: ['admin'] 
          },
          component: () => import("./components/admin/ApprovedAppsAdmin.vue"),
        },
        {
          path: "/ApprovedApplications",
          name: "ApprovedApplications",
          meta: {
            requireAuth: true,roles: ['admin','division'] 
          },
          component: () => import("./components/division/ApprovedApplications.vue"),
        },
        {
          path: "/DivisionMybooking",
          name: "DivisionMybooking",
          meta: {
            requireAuth: true,roles: ['division'] 
          },
          component: () => import("./components/division/DivisionMybooking.vue"),
        },

        {
          path: "/ApplicationDivision",
          name: "ApplicationDivision",
          meta: {
            requireAuth: true,roles: ['division']
          },
          component: () => import("./components/division/ApplicationDivision.vue"),
        },
        {
          path: "/CancelRequest",
          name: "CancelRequest",
          meta: {
            requireAuth: true,roles: ['division']
          },
          component: () => import("./components/division/CancelRequest.vue"),
        },
        {
          path: "/PendingApplicationDivision",
          name: "PendingApplicationDivision",
          meta: {
            requireAuth: true,roles: ['division']
          },
          component: () => import("./components/division/PendingApplicationDivision.vue"),
        },
        {
          path: "/ApplicationSuperintedent",
          name: "ApplicationSuperintedent",
          meta: {
            requireAuth: true,roles: ['superint']
          },
          component: () => import("./components/superintedent/ApplicationSuperintedent.vue"),
        },
        {
          path: "/CampDetailedViewDivision",
          name: "CampDetailedViewDivision",
          meta: {
            requireAuth: true,roles: ['division']
          },
          component: () => import("./components/division/CampDetailedViewDivision.vue"),
        },
        {
          path: "/DivisionCancelView",
          name: "DivisionCancelView",
          meta: {
            requireAuth: true,roles: ['division']
          },
          component: () => import("./components/division/DivisionCancelView.vue"),
        },
        {
          path: "/CampDetailedDivision",
          name: "CampDetailedDivision",
          meta: {
            requireAuth: true,roles: ['superint','division','admin']
          },
          component: () => import("./components/division/CampDetNew.vue"),
        },
        {
          path: "/blockdate",
          name: "blockdate",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/division/BlockDate.vue"),
        },
        {
          path: "/CampDetailedAdmin",
          name: "CampDetailedAdmin",
          meta: {
            requireAuth: true,roles: ['admin']
          },
          component: () => import("./components/admin/CampDetailedAdmin.vue"),
        },
        {
          path: "/CancelledApplicationClerk",
          name: "CancelledApplicationClerk",
          meta: {
            requireAuth: true,roles: ['clerk'] 
          },
          component: () => import("./components/clerk/CancelledAppClerk.vue"),
        },
        {
          path: "/CampDetailedViewClerk",
          name: "CampDetailedViewClerk",
          meta: {
            requireAuth: true,roles: ['clerk']
          },
          component: () => import("./components/clerk/CampDetailedViewClerk.vue"),
        },
        {
          path: "/Adminclerkview",
          name: "Adminclerkview",
          meta: {
            requireAuth: true,roles: ['adminclerk']
          },
          component: () => import("./components/cwwclerk/CwwdetailedView.vue"),
        },
        {
          path: "/CampDetailedViewSuperint",
          name: "CampDetailedViewSuperint",
          meta: {
            requireAuth: true,roles: ['superint']
          },
          component: () => import("./components/superintedent/CampDetailedViewSuperint.vue"),
        },


        {
          path: "/report",
          name: "report",
          meta: {
            requireAuth: true,
          },
          component: () => import("./components/division/Report.vue"),
        },

        {
          path: "/divisiondashboard",
          name: "divisiondashboard",
          meta: {
            requireAuth: true,roles: ['division']
          },
          component: () => import("./components/division/Dashboard.vue"),
        },

        {
          path: "/CancelledBookings",
          name: "CancelledBookings",
          meta: {
            requireAuth: true,roles: ['division']
          },
          component: () => import("./components/division/CancelledBookings.vue"),
        },
        {
          path: "/BookingTomorrow",
          name: "BookingTomorrow",
          meta: {
            requireAuth: true,roles: ['division']
          },
          component: () => import("./components/division/BookingTomorrow.vue"),
        },

        {
          path: "/adminnaturecamp",
          name: "adminnaturecamp",
          meta: {
            requireAuth: true,roles: ['admin'] 
          },
          component: () => import("./components/admin/NatureCampAdmin.vue"),
        },

        {
          path: "/addcamp",
          name: "addcamp",
          meta: {
            requireAuth: true,roles: ['admin'] 
          },
          component: () => import("./components/admin/AddNatureCamp.vue"),
        },

        {
          path: "/editib",
          name: "editib",
          meta: {
            requireAuth: true,roles: ['admin','division'] 
          },
          component: () => import("./components/admin/EditIb.vue"),
        },
        {
          path: "/test",
          name: "test",


          component: () => import("./components/TestPage"),
        },
      ],
    },
    {
      path: "/",
      name: "LoginPage",
      meta: {
        requireAuth: false,
      },
      component: () => import("./components/Login/LoginPage"),
    },
    {
      path: "/register",
      name: "register",
      meta: {
        requireAuth: false,
      },
      component: () => import("./components/Login/RegisterPage"),
    },
    {
      path: "/verify",
      name: "verify",
      meta: {
        requireAuth: false,
      },
      component: () => import("./components/Login/oneTimepass"),
    },
    {
      path: "/forgotpassword",
      name: "forgotpassword",
      meta: {
        requireAuth: false,
      },
      component: () => import("./components/Login/ForgotPassword"),
    },
    {
      path: '/OtpPage',
      name: 'OtpPage',
      meta: {
        requireAuth: false,
      },
      component: function () {
        return import(/* webpackChunkName: "about" */ './components/Login/OtpPage.vue')
      }
    },
    // {
    //     path: '/',
    //     props: true,
    //     name: 'AdminDashboard',
    //     component: () =>
    //         import ('./components/Common/appDashboard'),
    //     meta: {
    //         requiresAuth: true,
    //     },
    // },
    {
      path: "/ServerError",
      name: "ServerError",
      props: true,
      component: () => import("./components/Common/500"),
    },
    {
      path: "*",
      name: "404PageNotFound",
      props: true,
      meta: {
        requiresAuth: false,
      },
      component: () => import("./components/Common/404"),
    },
  ],
});
router.beforeEach((to, from, next) => {
  if (
    to.matched.some((route) => route.meta.requireAuth == true) &&
    !store.state.isLoggedIn
  ) {
    next({ name: "LoginPage", params: { lastPage: from } });
    return;
  }

  if (store.state.isLoggedIn) {
    axios({
      method: "GET",
      url: "/token/authenticate",
      headers: {
        token: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (!response.data.status) {
          store.commit("sessionOut", true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // if (to.name == "LoginPage" && store.state.isLoggedIn) {
  //   const userType = getUserType();
  //   console.log("User Type:", userType);

  //   if (userType == "admin") {
  //     console.log("admin");
  //     next({ name: "DashboardPage" });
  //   } else if (userType == "user") {
  //     console.log("user");
  //     next({ name: "NatureCamps" });
  //   }
  //   else if (userType == "clerk") {
  //     console.log("user");
  //     next({ name: "ClerkDashboard" });
  //   }
  //   else if (userType == "superint") {
  //     console.log("user");
  //     next({ name: "SuperintedentDashboard" });
  //   } else if (userType == "adminsuperint") {
  //     console.log("user");
  //     next({ name: "CwwministerialDashboard" });
  //   } else if (userType == "adminclerk") {
  //     console.log("user");
  //     next({ name: "cwwclerkdashboard" });
  //   } else {
  //     next({ name: "divisiondashboard" });
  //     console.log("division");
  //   }
  //   return;
  // }
  if (to.name === 'LoginPage' && store.state.isLoggedIn) {
    redirectToDashboard();
    return;
  }
  if (to.matched.some(route => route.meta.requireAuth)) {
    const userType = getUserType();
    const isAuthorized = to.matched.every(route => {
      return route.meta.roles ? route.meta.roles.includes(userType) : true;
    });

    if (!isAuthorized) {
      next({ name: '404PageNotFound' });
      return;
    }
  }

  next();
});

function getUserType() {
  const uType = localStorage.getItem('uType');
  console.log('uType:', uType);
  if (uType === '777') {
    return 'admin';
  } else if (uType === '772') {
    return 'user';
  } else if (uType === '7741') {
    return 'clerk';
  } else if (uType === '7742') {
    return 'superint';
  } else if (uType === '7771') {
    return 'adminclerk';
  } else if (uType === '7772') {
    return 'adminsuperint';
  } else {
    return 'division';
  }
}

function redirectToDashboard() {
  const userType = getUserType();
  console.log('User Type:', userType);

  if (userType === 'admin') {
    console.log('admin');
    router.push({ name: 'DashboardPage' });
  } else if (userType === 'user') {
    console.log('user');
    router.push({ name: 'NatureCamps' });
  } else if (userType === 'clerk') {
    console.log('user');
    router.push({ name: 'ClerkDashboard' });
  } else if (userType === 'superint') {
    console.log('user');
    router.push({ name: 'SuperintedentDashboard' });
  } else if (userType === 'adminsuperint') {
    console.log('user');
    router.push({ name: 'CwwministerialDashboard' });
  } else if (userType === 'adminclerk') {
    console.log('user');
    router.push({ name: 'cwwclerkdashboard' });
  } else {
    router.push({ name: 'divisiondashboard' });
    console.log('division');
  }
}


export default router